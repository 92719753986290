<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title style="word-break: normal;">
        Depositos
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row>
            <!-- Tipo Movimiento, formato y tipo de cheque, Comprobante, Importe y Fechas -->
            <v-col cols="6" sm="4" :md="tipo_mov == 3 ? 2 : 3" class="py-0">
              <v-autocomplete
                v-model="tipo_mov"
                label="Movimiento"
                item-text="nombre"
                item-value="codigo"
                :items="tipos_movimiento.filter(tm => tm.inhabilitado != 1)"
                :rules="[rules.required]"
                :readonly="concepto != 126"
                :filled="concepto != 126"
                validate-on-blur
                outlined
                dense
                @change="limpiar()"
              ></v-autocomplete>
            </v-col>
            <template v-if="tipo_mov == 3">
              <v-col cols="6" sm="3" md="2" class="py-0">
                <v-autocomplete
                  v-model="formato_cheque"
                  label="Formato"
                  item-text="nombre"
                  item-value="id"
                  :items="formatos_cheque"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="3" class="py-0">
                <v-autocomplete
                  v-model="tipo_cheque"
                  label="Tipo"
                  item-text="nombre"
                  item-value="id"
                  :items="tipos_cheque"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="5" class="py-0">
                <v-row>
                  <v-col>
                    <FechaPicker
                      v-model="fecha_emsion"
                      label="Emisión"
                    />
                  </v-col>
                  <v-col>
                    <FechaPicker
                      v-model="fecha_vencimiento"
                      label="Vencimiento"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </template>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <v-text-field
                v-model.trim="num_comp"
                label="Nº Comprobante"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" class="py-0">
              <v-text-field-money
                v-model.trim="importe"
                label="Importe"
                v-bind:properties="{
                  prefix: '$',
                  type: 'tel',
                  outlined: true,
                  readonly: true,
                  filled: true,
                  dense: true
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  precision: 2,
                  empty: null
                }"
              />
            </v-col>
            <v-col v-if="tipo_mov != 3" cols="6" sm="3" class="py-0">
              <FechaPicker
                v-model="fecha"
                :fecha_maxima="moment(new Date()).format('DD/MM/YYYY')"
                label="Fecha"
              />
            </v-col>
            <!-- Banco / Cuenta / CBU -->
            <v-col cols="12" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="banco"
                label="Banco"
                item-text="nombre"
                item-value="codigo"
                :items="bancos.filter(ba => ba.cuentas > 0)"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="cuenta = null; cbu = null; setCuenta()"
                v-if="tipo_mov != 3"
              ></v-autocomplete>
              <v-autocomplete
                v-model="banco_pais"
                label="Banco Emisor"
                item-text="banco_pais_nombre"
                item-value="banco_pais_id"
                :items="bancos_pais"
                validate-on-blur
                outlined
                dense
                @change="cuenta = null; cbu = null"
                v-if="tipo_mov == 3"
              ></v-autocomplete>
            </v-col>
            <template v-if="tipo_mov != 3">
              <v-col cols="12" sm="4" md="3" class="py-0">
                <v-autocomplete
                  v-model="cuenta"
                  label="Cuenta"
                  item-text="numero"
                  item-value="codigo"
                  :items="all_cuentas.filter(cu => cu.inhabilitada != 1 && cu.banco_codigo == banco && cu.empresa_codigo == empresa)"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                  @change="setCBU()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" class="py-0">
                <v-text-field
                  v-model="cbu"
                  label="CBU"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </template>
            <!-- Cliente -->
            <template v-if="concepto == 126">
              <v-col cols="8" sm="3" class="py-0">
                <v-text-field
                  v-model.trim="cliente_codigo"
                  label="Cliente Código"
                  type="number"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                  @blur="buscarCliente()"
                >
                  <template v-slot:append-outer>
                    <v-icon
                      class="mt-1 ml-2"
                      color="info"
                      title="Buscar cliente"
                      small
                      @click="dialog_bsucar = true"
                    >
                      fas fa-search
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="4" class="py-0">
                <v-text-field
                  v-model="cliente_nombre"
                  label="Nombre"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </template>
            <!-- Beneficiario -->
            <template v-if="tipo_mov == 3">
              <v-col cols="6" sm="4" class="py-0">
                <v-text-field
                  v-model.trim="beneficiario"
                  label="Beneficiario"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="py-0">
                <v-text-field-integer
                  v-model="beneficiario_cuit"
                  label="Beneficiario CUIT"
                  v-bind:properties="{
                    type: 'tel',
                    'validate-on-blur': true,
                    outlined: true,
                    dense: true
                  }"
                  v-bind:options="{
                    inputMask: '##-########-#',
                    outputMask: '###########',
                    empty: null
                  }"
                />
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          clase="ml-2"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
    <!-- componentes -->
    <BuscarCliente
      v-model="dialog_bsucar"
      @seleccion="setCliente"
    />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { parseDate, cadenaVacia } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar'
import FechaPicker from '../util/FechaPicker'
import BuscarCliente from './BuscarCliente'
import moment from 'moment'

export default {
  data () {
    return {
      moment: moment,
      load: false,
      dialog_bsucar: false,
      tipo_mov: null,
      trans_id: null,
      formato_cheque: null,
      tipo_cheque: null,
      fecha_emsion: null,
      fecha_vencimiento: null,
      fecha: moment(new Date()).format('DD/MM/YYYY'),
      banco: null,
      cuenta: null,
      cbu: null,
      cliente_codigo: null,
      cliente_nombre: null,
      beneficiario: null,
      beneficiario_cuit: null,
      rules: {
        required: value => !!value || 'Campo requerido',
        max: value => (value ? value.length : 0) <= 50 || 'Máximo 50 caracteres'
      },
      bancos_pais: [],
      banco_pais: null
    }
  },
  props: {
    value: Boolean,
    cj_numero: Number,
    ca_numero: Number,
    ca_nivel: Number,
    origen: Number,
    concepto: Number,
    referencia: String,
    num_comp: String,
    importe: Number,
    empresa: Number,
    comp_id: Number
  },
  computed: {
    ...mapState('bancos', ['bancos', 'all_cuentas', 'tipos_movimiento']),
    ...mapState('cheques', ['tipos_cheque', 'formatos_cheque']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    FechaPicker,
    BuscarCliente
  },
  watch: {
    async dialog (val) {
      if (val) {
        await new Promise(resolve => setTimeout(resolve, 1))
        // si es deposito egreso seteo el mov en deposito
        if (this.concepto == 9) this.tipo_mov = 1
        // obtengo los datos
        this.$store.state.loading = true
        await this.$store.dispatch('bancos/get_bancos_habilitados', this.empresa)
        await this.$store.dispatch('bancos/get_all_cuentas')
        await this.$store.dispatch('bancos/get_tipos_movimiento')
        await this.$store.dispatch('cheques/get_tipos')
        await this.$store.dispatch('cheques/get_formatos')
        let bcoPeticion = await this.$store.dispatch('bancos/get_bancos_pais')
        if (bcoPeticion.exito == 1) this.bancos_pais = bcoPeticion.bancos_pais
        this.$store.state.loading = false
        if (this.comp_id != null) this.getMovimiento()
      }
      else {
        this.tipo_mov = null
        this.$refs.form.resetValidation()
        this.limpiar()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        if (parseFloat(this.importe.toFixed(2)) <= 0) {
          return this.$store.dispatch('show_snackbar', {
            text: 'El importe debe ser mayor a 0',
            color: 'orange'
          })
        }
        if (this.tipo_mov != 3 && this.fecha == null) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una fecha',
            color: 'orange'
          })
        }
        if (this.tipo_mov == 3 && (this.fecha_emsion == null || this.fecha_vencimiento == null)) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una fecha de emisión y vencimiento',
            color: 'orange'
          })
        }
        if (this.tipo_mov == 3 && moment(parseDate(this.fecha_emsion)).isAfter(parseDate(this.fecha_vencimiento))) {
          return this.$store.dispatch('show_snackbar', {
            text: 'La fecha de emisión no puede ser mayor a la fecha de vencimiento',
            color: 'orange'
          })
        }
        if (this.tipo_mov == 3 && (this.banco_pais == null || this.banco_pais == undefined)){
          return this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar el Banco Emisor',
            color: 'orange'
          })
        }

        let accion = this.comp_id == null ? 'nuevo' : 'editar'

        this.load = true
        let resultado = await this.$store.dispatch(`caja/${accion}_comprobante`, {
          caja_numero: this.ca_numero,
          comprobanteid: this.concepto,
          importe: parseFloat(this.importe.toFixed(2)),
          numero_comprobante: this.num_comp,
          referencia: this.referencia,
          banco_cuenta: this.cuenta,
          tipo_movimiento: this.tipo_mov,
          empresa_codigo: this.empresa,
          fecha: this.fecha,
          origen_deposito: this.origen,
          caja_jerarquia: this.cj_numero,
          interes: 0,
          comprobante_id: this.comp_id,
          transaccion_id: this.trans_id,
          cliente_codigo: this.cliente_codigo,
          fecha_emision: this.fecha_emsion,
          fecha_vencimiento: this.fecha_vencimiento,
          formato: this.formato_cheque,
          tipo_cheque: this.tipo_cheque,
          beneficiario: this.beneficiario,
          beneficiario_cuit: this.beneficiario_cuit,
          banco_pais: this.banco_pais
        })
        this.load = false

        if (resultado.exito == 1) {
          this.dialog = false
          this.$emit('exito_deposito')
          this.$swal.fire({
            icon: 'success',
            title: resultado.message
          })
        } else {
          this.$swal.fire({
            icon: 'error',
            title: resultado.message
          })
        }

      }
    },
    async getMovimiento () {
      this.$store.state.loading = true
      let respuesta = await this.$store.dispatch('caja/get_movimiento', this.comp_id)
      this.$store.state.loading = false

      if (respuesta.exito == 1) {
        const mov = respuesta.movimiento
        this.trans_id = mov.id
        this.fecha = mov.fecha
        this.banco = mov.banco_codigo
        this.banco_pais = (mov.banco_emisor == 0 || mov.banco_emisor == null || mov.banco_emisor == undefined) ? null : mov.banco_emisor
        this.cuenta = mov.cuenta_codigo
        if (cadenaVacia(this.banco_pais)) this.setCBU()
      } else {
        this.dialog = false
        this.$swal.fire({
          icon: 'error',
          title: respuesta.message,
          text: `Comprobante Nº ${this.comp_id}`
        })
      }
    },
    async buscarCliente () {
      if (this.cliente_codigo) {
        this.$store.state.loading = true
        let respuesta = await this.$store.dispatch('caja/get_clientes', { codigo: this.cliente_codigo })
        this.$store.state.loading = false

        if (respuesta.exito == 1) {
          switch (respuesta.clientes.length) {
            case 0:
              this.$store.dispatch('show_snackbar', {
                text: 'No se encontró ningún cliente con código ' + this.cliente_codigo,
                color: 'info'
              })
              this.cliente_codigo = null
              this.cliente_nombre = null
              break
            case 1:
              let cliente = respuesta.clientes[0]
              if (cliente.inhabilitado == 1) {
                this.cliente_codigo = null
                this.cliente_nombre = null
                return this.$swal.fire({
                  icon: 'warning',
                  title: `Cliente ${cliente.nombre} inhabilitado`,
                  text: cliente.perfil_nombre
                })
              }
              this.cliente_nombre = cliente.nombre
              break
            default:
              this.$store.dispatch('show_snackbar', {
                text: 'Se encontraron múltiples coincidencias para el cliente con código ' + this.cliente_codigo,
                color: 'info'
              })
              this.cliente_codigo = null
              this.cliente_nombre = null
              break
          }
        } else {
          this.cliente_codigo = null
          this.cliente_nombre = null
          this.$store.dispatch('show_snackbar', {
            text: respuesta.message,
            color: 'error'
          })
        }
      }
    },
    setCuenta () {
      let banco = this.bancos.find(ba => ba.codigo == this.banco)
      if (banco) {
        let cuentas = this.all_cuentas.filter(cu => cu.inhabilitada != 1 && cu.banco_codigo == banco.codigo && cu.empresa_codigo == this.empresa)
        if (cuentas.length == 1) {
          this.cuenta = cuentas[0].codigo
          this.cbu = cuentas[0].cbu
        }
      }
    },
    setCBU () {
      let cuenta = this.all_cuentas.find(cu => cu.codigo == this.cuenta && cu.empresa_codigo == this.empresa)
      this.cbu = cuenta ? cuenta.cbu : null
    },
    setCliente (cliente) {
      this.cliente_codigo = cliente.codigo
      this.cliente_nombre = cliente.nombre
    },
    limpiar () {
      this.trans_id = null
      this.formato_cheque = null
      this.tipo_cheque = null
      this.fecha_emsion = null
      this.fecha_vencimiento = null
      this.fecha = moment(new Date()).format('DD/MM/YYYY')
      this.banco = null
      this.banco_pais = null
      this.cuenta = null
      this.cbu = null
      this.cliente_codigo = null
      this.cliente_nombre = null
      this.beneficiario = null
      this.beneficiario_cuit = null
    }
  }
}
</script>